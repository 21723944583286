
function handleResize() {
    const windowWidth = window.innerWidth;

    const resolutionThreshold = 1199;

    let offCanvasTag = document.querySelector('.offcanvas.offcanvas-start');
    let offCanvasBackdropTag = document.querySelector('.offcanvas-backdrop');

    if (windowWidth > resolutionThreshold) {
        if (offCanvasTag && offCanvasBackdropTag) {
            if (offCanvasTag.classList.contains("show")) {
                offCanvasTag.classList.remove('show');
                offCanvasBackdropTag.classList.remove('show');
                body.style.overflow = '';
            }
        }
    }
}

// window.addEventListener('scroll', function() {
//     const elements = [
//         { id: 'elemento', scrollPos: 10, className: 'oculto' },
//         { id: 'banner-right', scrollPos: 60, className: 'oculto' },
//         { id: 'btn-banner', scrollPos: 70, className: 'oculto' },
//         { id: 'nick-logo', scrollPos: 80, className: 'oculto' },
//         { selector: 'footer', scrollPos: 90, className: 'oculto' },
//         { selector: 'header', scrollPos: 0, className: 'visible' }
//     ];
//
//     const currentScrollPos = window.scrollY;
//
//     elements.forEach(element => {
//         const el = element.id ? document.getElementById(element.id) : document.querySelector(element.selector);
//         if (el) {
//             if (currentScrollPos > element.scrollPos) {
//                 el.classList.add(element.className);
//             } else {
//                 el.classList.remove(element.className);
//             }
//         }
//     });
// });



document.addEventListener("DOMContentLoaded", handleResize);
window.addEventListener('resize', handleResize);